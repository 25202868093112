var map;

/**
 * Init Google Map
 */
function initMap(){
    var pos = new google.maps.LatLng(53.577273, 6.680835);
    map = new google.maps.Map(document.getElementById('gmap'), {
        zoom: 10,
        center: pos,
        mapTypeId: 'roadmap',
        disableDefaultUI: true
    });
    setTimeout(function(){
        var marker = new google.maps.Marker(
            {
                position: pos,
                map: map,
                draggable: false,
                animation: google.maps.Animation.DROP,
                title: "Dünenzauber"
            });
    }, 1000);
}