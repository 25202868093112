
var grey = "#3a3a3a",
blue = "#2787c7",
lightBlue = "#e8f3f8",
sand = "#f9eddc",
text = "#000",
shadow = "#d5dcdf",
shadowDark = "#c6c6c6";


$(document).scroll(function(){
    calcDynHeights();
    checkVisibility();
});

$(document).ready(function(){
    checkMobile();
    calcDynHeights();
    checkVisibility();

    // Initialize Sliders
    $('#slick-head').slick({
        dots: true,
        infinite: true,
        speed: 300,
        lazyLoad: 'ondemand',
        arrows: false,
        fade: true,
        cssEase: 'linear',
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    dots: false
                }
            }
        ]
    });

    $('#slick-gallery').on('init', function(slick){
        var slideCount = $('#slick-gallery .slick-slide:not(.slick-cloned)').length;

        $('.counter .slick-count-nr').html(slideCount);
    });

    $('#slick-gallery').on('afterChange', function(event, slick, currentSlide, nextSlide){

        $('.counter .slick-cur-nr').html(currentSlide+1);
    });

    $('#slick-gallery').slick({
        dots: false,
        lazyLoad: 'ondemand',
        arrows: true
    });


    // Register navigation links
    $("a[href^='#']").click(function(e){
        e.preventDefault();

        $('.navbar-collapse').removeClass('in');

       var id = $(this).attr('href');
       id = id.substr(id.indexOf('#')+1);
       scrollToAnchor(id);
    });

});

$(window).resize(function(){
   checkMobile();
});

/**
 * Check if mobile and set body class
 */
function checkMobile(){

    $('body').removeClass('xs sm md lg');

    $('body').addClass(findBootstrapEnvironment());

    $('.navbar').fadeIn();
    $('.head-slider').css('visibility', 'visible');
}

/**
 * check current bootstrap environment
 * @returns {string}
 */
function findBootstrapEnvironment() {
    var envs = ['xs', 'sm', 'md', 'lg'];

    var $el = $('<div>');
    $el.appendTo($('body'));

    for (var i = envs.length - 1; i >= 0; i--) {
        var env = envs[i];

        $el.addClass('hidden-'+env);
        if ($el.is(':hidden')) {
            $el.remove();
            return env;
        }
    }
}

/**
 * Scroll to anchor
 * @param aid
 */
function scrollToAnchor(aid){
    var aTag = $("*[id='"+ aid +"']");
    if(aTag.length) {
        $('html,body').animate({scrollTop: aTag.offset().top-100},'slow');
    }
}

/**
 * Calculate heights based on page scroll
 */
function calcDynHeights(){
    var st = $(document).scrollTop();
    if(st < 700){
        $('.navbar-fixed-top').css({
            'background-color': 'rgba(58,58,58,'+(Math.ceil(st/7)/100)+')'
        });
    } else {
        $('.navbar-fixed-top').css({
            'background-color': grey
        });
    }

    if(st < 25){
        $('.navbar-fixed-top').removeClass('stick-top');
    } else {
        $('.navbar-fixed-top').addClass('stick-top');
    }
}

/**
 * Check occuring visibility of objects and lazy load images
 */
function checkVisibility(){
    $('section, footer').each(function(){
       var top = $(this).offset().top;
       var cur = $(document).scrollTop() + $(window).height();

       if(cur > top-100){
           $(this).addClass('load');
       }
    });
}
